import { Container, Flex, StyleProps } from "@chakra-ui/react";
import React, { FC } from "react";

const Section: FC<
  StyleProps & {
    children: React.ReactNode;
  }
> = ({ children, ...flexProps }) => {
  return (
    <Flex w="full" p={2} {...flexProps}>
      <Container
        px={{md:2 , xl: 0}}
        maxW={[
          "full",
          "container.sm",
          "container.md",
          "container.lg",
          "container.xl",
          "container.2xl",
        ]}
      >
        {children}
      </Container>
    </Flex>
  );
};

export default Section;
